<template>
  <v-navigation-drawer class="secondary" fixed v-model="showAppMenu" app style="z-index: 10">
    <v-list>
      <v-list-item>
        <v-list-item-title class="title">
          {{ dashboardName }}
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list dense>
      <v-list-item
        v-if="user.business && !hasSubscription"
      >
        <v-list-item-content>
          <v-btn
            class="mb-3"
            color="success"
            @click="setShowSubscriptionDialog(true)"
            >Upgrade</v-btn
          >
        </v-list-item-content>
      </v-list-item>

      <template v-for="(item, i) in menuItems">
        <v-list-group
          :to="'/'"
          v-if="item.children"
          v-model="item.model"
          :key="i"
          :prepend-icon="item.icon"
          append-icon="fal fa-angle-down"
          color="primary"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}

                <subscription-crown-icon
                   v-if="item.permission && ! hasPermission(item.permission)"
                   class="ml-2 mb-1"
                ></subscription-crown-icon>
                <v-chip
                  v-if="item.isBeta"
                  color="#3AC2D2"
                  class="white--text ml-2 cursor-pointer"
                  x-small
                >
                  Beta
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, k) in item.children"
            :to="child.link ? { name: child.link } : null"
            :href="null"
            :key="`${i}-${k}`"
            :exact="child.exact"
            :target="child.href ? '_blank' : null"
            @click="selectItem(`${i}-${k}`)"
            class="app-list-item"
            :style="
              selected === `${i}-${k}`
                ? `background-color: ${selectedItemBackground} !important`
                : ''
            "
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
                <subscription-crown-icon
                    v-if="child.permission && ! hasPermission(child.permission)"
                    class="ml-2 mb-1"
                ></subscription-crown-icon>

                <v-chip
                   v-if="child.isNew"
                   color="success"
                   class="white--text ml-2 cursor-pointer"
                   x-small
                >
                  New
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :to="item.link ? { name: item.link } : null"
          :href="item.href ? item.href : null"
          :exact="item.exact"
          :key="item.text"
          :target="item.href ? '_blank' : null"
          @click="selectItem(i)"
          color="primary"
          class="app-list-item"
          :data-intercom-target="'menu.' + item.link"
          :style="
            selected === i
              ? `background-color: ${selectedItemBackground} !important`
              : ''
          "
        >
          <v-list-item-action>
            <v-icon color="primary" class="icon-width">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}

              <v-chip
                 v-if="item.isNew"
                 color="success"
                 class="white--text ml-2 cursor-pointer"
                 x-small
              >
                New
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
             <v-badge
                v-if="item.badge"
                  color="accent"
                  right
                  offset-y="10px"
                  :value="campaignInvitationsCount"
                >
                <template v-slot:badge>
                  {{ campaignInvitationsCount }}
                </template>
              </v-badge>
          </v-list-item-action>
        </v-list-item>
      </template>

<!--      <template v-if="! user.moderator">-->
<!--        <v-divider class="mt-4 mb-3"></v-divider>-->

<!--        <v-subheader>Brug for hjælp? 😄</v-subheader>-->

<!--        <v-list-item>-->
<!--          <v-list-item-action>-->
<!--            <v-icon>fal fa-envelope</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>-->
<!--              <a-->
<!--                href="mailto:support@makeinfluence.com"-->
<!--                class="primary&#45;&#45;text"-->
<!--                style="text-decoration: none;"-->
<!--                >support@makeinfluence.com</a-->
<!--              >-->
<!--            </v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->

<!--        <v-list-item v-if="successManager.phone">-->
<!--          <v-list-item-action>-->
<!--            <v-icon>fal fa-mobile-android-alt</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>-->
<!--              <a-->
<!--                :href="'tel:' + successManager.phone"-->
<!--                class="primary&#45;&#45;text"-->
<!--                style="text-decoration: none;"-->
<!--                >{{ successManager.phone_display }}</a-->
<!--              >-->
<!--            </v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </template>-->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import SubscriptionCrownIcon from "../business/icon/SubscriptionCrownIcon";

export default {
  components: {SubscriptionCrownIcon},
  data: () => ({
    selected: 0,
    campaignInvitationsCount: 0,
  }),
  props: {
    value: {
      required: true
    }
  },
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapState("settings", ["settings"]),
    ...mapGetters("subscriptions", ["hasSubscription", "hasPermission"]),
    ...mapGetters("core/auth", ["isFinanceUser", "isTechUser"]),
    showAppMenu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    dashboardName() {
      if (this.user.agency) {
        return this.user.agency.name;
      } else if (this.user.business) {
        return this.user.business.display_name;
      } else if (this.user.influencer) {
        return "Make Influence";
      } else if (this.user.moderator) {
        return "Admin";
      }

      return "";
    },
    menuItems() {
      if (this.user.agency) {
        return [
          { icon: "fal fa-home", text: "Dashboard", link: "home", exact: true },
          {
            icon: "fal fa-search",
            text: "Find influencers",
            link: "influencers",
            exact: true
          },
          {
            icon: "fal fa-building",
            text: "Businesses",
            link: "businesses",
            exact: true
          },
          {
            icon: "fal fa-bullhorn",
            text: "Campaigns",
            link: "campaigns",
            exact: true
          },
          {
            icon: "fal fa-chart-line",
            text: "Reports",
            model: false,
            children: [
              { icon: "", text: "Daily", link: "report.daily" },
              { icon: "", text: "Sales", link: "report.sales" },
              { icon: "", text: "Influencer", link: "report.influencer" },
              { icon: "", text: "Campaign", link: "report.campaign" }
            ]
          }
        ];
      } else if (this.user.business) {
        let items = [
          { icon: "fal fa-home", text: "Dashboard", link: "home", exact: true },
          {
            icon: "fal fa-bullhorn",
            text: "Campaigns",
            link: "campaigns.index"
          },
          {
            icon: "fal fa-search",
            text: "Find influencers",
            link: "influencers"
          },
          {
            icon: "fal fa-chart-line",
            text: "Reports",
            model: false,
            children: [
              { icon: "", text: "Summary", link: "report.overall" },
              { icon: "", text: "Daily", link: "report.daily" },
              { icon: "", text: "Campaign", link: "report.campaign" },
              { icon: "", text: "Influencer", link: "report.influencer" },
              { icon: "", text: "Sales", link: "report.sales" },
              { icon: "", text: "Traffic", link: "report.traffic" },
              { icon: "", text: "Commission", link: "report.commission" }
            ]
          },
          {
            icon: "fal fa-photo-video",
            text: "Content",
            model: false,
            isBeta: true,
            children: [
              { icon: "", text: "Save posts", link: "posts.attach" },
              { icon: "", text: "My posts", link: "posts.detach" },
              { icon: "", text: "Instagram report", link: "posts.instagram_report" },
            ]
          }
          // { icon: 'fal fa-code', text: 'Tracking kode', link: 'tracking' },
        ];

        if(! this.user.active_subscription || this.user.active_subscription.stripe_id !== 'shopify') {
          items.push({ icon: "fal fa-receipt", text: "Invoices", link: "invoices" });
        }

        // Only show route if shopify
        if (this.user.business.promotion_code_tracking_approved_at) {
          items.push({
            icon: "fal fa-badge-percent",
            text: "Influencer promotion codes",
            link: "influencer_promotion_codes"
          });
        }
        return items;
      } else if (this.user.influencer) {
        return [
          { icon: "fal fa-home", text: "Dashboard", link: "home", exact: true },
          {
            icon: "fal fa-rocket",
            text: "Promote campaign",
            link: "tracking_links"
          },
          { icon: "fal fa-images", text: "Media", link: "channel.index" },
          { icon: "fal fa-search", text: "Find campaigns", link: "campaigns" },
          {
            icon: "fal fa-bullhorn",
            text: "Your campaigns",
            link: "my.campaigns",
            badge: true
          },
          {
            icon: "fal fa-chart-line",
            text: "Reports",
            model: false,
            children: [
              { icon: "", text: "Commission", link: "report.commission" },
              { icon: "", text: "Summary", link: "report.overall" },
              { icon: "", text: "Daily", link: "report.daily" },
              { icon: "", text: "Campaigns", link: "report.campaign" },
              { icon: "", text: "Businesses", link: "report.business" },
              { icon: "", text: "Sales", link: "report.sales" },
              { icon: "", text: "Instagram", link: "report.instagram" }
            ]
          },
          { icon: "fal fa-money-bill", text: "Payouts", link: "payouts" },
          {
            icon: "fal fa-money-check-edit",
            text: "Payout account",
            link: "payout_account"
          },
          {
            icon: "fal fa-badge-percent",
            text: "Promotion codes",
            link: "influencer_promotion_codes"
          }
        ];
      } else if (this.user.moderator) {
        let items = [];
        items.push({ icon: "fal fa-home", text: "Dashboard", link: "home", exact: true });
        items.push({
          icon: "fal fa-bacon",
          text: "Creme de la creme",
          link: "creme_de_la_creme",
          exact: true
        });
        if(this.isFinanceUser) {
          items.push({
            icon: "fal fa-coins",
            text: "Finance",
            model: false,
            children: [
              {
                icon: "",
                text: "Influencer transfer",
                link: "influencer_transfers",
                exact: true
              },
              {
                icon: "",
                text: "Legacy transfer",
                link: "legacy_transfers",
                exact: true
              },
              // {
              //   icon: "",
              //   text: "Monthly Overview",
              //   link: "monthly_overview",
              //   exact: true
              // }
            ]
          });
          // This is a temporary solution for testing only
          if(this.isTechUser) {
            items.push({
              icon: "fal fa-coins",
              text: "Monthly Overview",
              link: "monthly_overview",
              exact: true
            });
          }
        }

        items.push({
          icon: "fal fa-hand-holding-usd",
          text: "Commission overview",
          link: "commission_overview",
          exact: true
        });

        items.push({
          icon: "fal fa-bullhorn",
          text: "Campaigns",
          link: "campaigns",
          exact: true
        });

        items.push({
          icon: "fab fa-instagram",
          text: "Influencers",
          model: false,
          children: [
            {
              icon: "",
              text: "All influencers",
              link: "influencers",
              exact: true
            },
            {
              icon: "",
              text: "Social media approval",
              link: "application.influencer",
              exact: true
            }
          ]
        });

        items.push({
          icon: "fal fa-building",
          text: "Business",
          model: false,
          children: [
            {
              icon: "",
              text: "All businesses",
              link: "businesses",
              exact: true
            },
            {
              icon: "",
              text: "Managed reports",
              link: "managed_reports",
              exact: true
            },
            {
              icon: "",
              text: "Conversion logs",
              link: "conversion_logs",
              exact: true
            },
          ]
        });

        items.push({
          icon: "fal fa-chart-line",
          text: "Reports",
          model: false,
          children: [
            { icon: "", text: "Daily", link: "report.daily", exact: true },
            {
              icon: "",
              text: "Signups",
              link: "report.signups",
              exact: true
            },
            {
              icon: "",
              text: "Conversions",
              link: "report.sales",
              exact: true
            },
            {
              icon: "",
              text: "Influencers",
              link: "report.influencers",
              exact: true
            },
            {
              icon: "",
              text: "Businesses",
              link: "report.businesses",
              exact: true
            },
            {
              icon: "",
              text: "Campaigns",
              link: "report.campaigns",
              exact: true
            },
            {
              icon: "",
              text: "Commission",
              link: "report.commission",
              exact: true
            },
            {
              icon: "",
              text: "Clicks",
              link: "report.clicks",
              exact: true
            }
          ]
        });

        return items;
      }
      // Default to no items
      return [];
    },
    successManager() {
      return {
        phone: "+4588247472",
        phone_display: "88 24 74 72"
      };
    },
    selectedItemBackground() {
      return `${this.$theme.accent}19`;
    },
  },
  methods: {
    ...mapActions("subscriptions", ["setShowSubscriptionDialog"]),
    ...mapActions("core/campaigns", ["getCampaignRequestCountsForInfluencer"]),
    selectItem(key) {
      this.selected = key;
      this.$forceUpdate();
    },
    getCountsForInfluencers() {
      this.getCampaignRequestCountsForInfluencer().then(campaignRequestCounts => {
        this.campaignInvitationsCount = campaignRequestCounts.pendingInvitationsCount;
      });
    }
  },
  created() {
    if (this.user.influencer) {
      this.getCountsForInfluencers();
    }
  }
};
</script>
<style lang="scss">
.v-navigation-drawer__border {
  display: none !important;
}
.app-list-item::before {
  opacity: 0 !important;
}
.icon-width {
  max-width: 20px;
}
</style>
